import React, { type ReactNode } from "react";
import { Pressable } from "react-native";
import { Icon, Row } from "../../quarks";

type Props = {
  editable?: boolean;
  onEdit: () => void;
  children: ReactNode;
};

export const EditableCell = ({ editable = true, onEdit, children }: Props) => {
  if (!editable) return <>{children}</>;
  return (
    <Pressable onPress={onEdit}>
      <Row gap="compact">
        {children}
        <Icon variant="solid" size="tiny" name="edit" />
      </Row>
    </Pressable>
  );
};
