import {
  DisableErrorReporting,
  SentryDebug,
  SentryTraceAll
} from "@gigsmart/feature-flags";
import { SentryReportingFallback } from "@gigsmart/feature-flags";
import * as Sentry from "@sentry/react-native";
import type { Options, Span as SentrySpan, SeverityLevel } from "@sentry/types";
import * as Updates from "expo-updates";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { Platform } from "react-native";
import { getBuildNumber } from "react-native-device-info";
import {
  type ErrorSeverity,
  addErrorHook,
  addIdentifyHook,
  addScreenHook,
  addTrackHook,
  collectHooks
} from "../../hooks";
import getUpdateMetaData from "./get-update-metadata";
import initConfig from "./init-config";

export { registerNavigationContainer } from "./init-config";

const isWeb = Platform.OS === "web";
const environment = compact([
  process.env.CONFIG_ENV,
  process.env.CONFIG_ENV && ["e2e", "review"].includes(process.env.CONFIG_ENV)
    ? process.env.ENV_PACKAGE_SLUG
    : null
]).join("-");
const buildNumber = Number(process.env.PACKAGE_BUILD_NUMBER ?? "") || -1;

const sentrySeverityMap: Record<ErrorSeverity, SeverityLevel> = {
  fatal: "fatal",
  critical: "error",
  error: "error",
  warning: "warning",
  log: "log",
  info: "info",
  debug: "debug"
};

const sampleRate = 1.0;
const tracesSampleRate = 0.0005;

async function configure(dsn: string, _update?: any | null) {
  const config: Options = {
    ...initConfig,
    sampleRate: SentryTraceAll.isEnabled() ? 1.0 : sampleRate,
    dsn,
    environment,
    ignoreErrors: ["Object is not a function"],
    denyUrls: [/https?:\/\/(.*\.)?rollout\.io/i],
    tracesSampleRate: SentryTraceAll.isEnabled() ? 1.0 : tracesSampleRate,
    _experiments: {
      profilesSampleRate: SentryTraceAll.isEnabled() ? 1.0 : tracesSampleRate
    },
    debug: SentryDebug.isEnabled(),
    autoSessionTracking: true,
    enabled: DisableErrorReporting.isDisabled(),
    beforeBreadcrumb: (breadcrumb) => {
      const shouldIgnore = breadcrumb?.data?.url?.includes?.("rollout.io");
      return shouldIgnore ? null : breadcrumb;
    },
    beforeSend(event, hint) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = hint?.originalException as any;
      if (String(event.tags?.url).includes?.("rollout.io")) return null;
      if (!error) return event;
      const message = typeof error === "string" ? error : error?.message ?? "";
      if (message.match(/Unable to send state!/i)) return null;
      if (message.match(/http error \(status: 0\)/i)) return null;
      return event;
    }
  };

  Sentry.init(config as any);

  Sentry.setTags({
    ...(isWeb ? { nativeBuild: Number(getBuildNumber()) } : {}),
    environment,
    buildNumber,
    buildDate: DateTime.fromSeconds(1543514400)
      .plus({
        minutes: buildNumber
      })
      .toISO(),
    react: "true",
    reactNativePlatform: Platform.OS
  });

  Sentry.setTag("buildNumber", buildNumber);

  if (Updates.updateId) {
    Sentry.getCurrentScope().setTag("expo-update-id", Updates.updateId);
  }

  return collectHooks([
    addTrackHook((message, data, category) =>
      Sentry.addBreadcrumb({ category, message, data })
    ),
    addScreenHook((name, data) => {
      let currentSpan: SentrySpan | undefined;
      Sentry.withScope(() => {
        Sentry.startSpan(
          { op: "screen", name, attributes: data as any },
          (span) => (currentSpan = span)
        );
      });

      return () => currentSpan?.end();
    }),
    addErrorHook((error, severity, context) => {
      Sentry.withScope((scope) => {
        if (context?.fingerprint) scope.setFingerprint(context.fingerprint);
        if (context?.tags) scope.setTags(context.tags);
        scope.setLevel(sentrySeverityMap[severity ?? "error"]);
        if (error.id) scope.setTag("error_code", error.id);
        Sentry.captureException(error);
      });
    }),
    addIdentifyHook((userId, { email, globalId, deviceId, isBot }) => {
      if (!email || !userId) return;
      Sentry.setUser({
        email: String(email),
        id: String(userId ?? "anonymous"),
        globalId,
        deviceId,
        isBot
      });
    })
  ]);
}

export default async () => {
  return await configure(
    SentryReportingFallback.isDisabled()
      ? process.env.SENTRY_REPORTING_URL ?? ""
      : process.env.SENTRY_REPORTING_URL_FALLBACK ?? "",
    await getUpdateMetaData()
  );
};
